import { Sparklines, SparklinesLine } from 'react-sparklines';

interface ChartProps {
  /**
   * Chart data timeseries
   */
  data: number[];
  width?: number;
  height?: number;
  margin?: number;
  color?: string;
}

interface NationalChartsProps {
  /**
   * Chart data timeseries for each target
   */
  data: {
    whitehouse: number[];
    senate: number[];
    house: number[];
  };
}

const Chart = ({
  data,
  width = 164,
  height = 48,
  margin = 4,
  color = '#f59e0b',
}: ChartProps) => {
  return (
    <Sparklines data={data} svgWidth={width} svgHeight={height} margin={margin}>
      <SparklinesLine color={color} />
    </Sparklines>
  );
};

const NationalCharts = ({ data }: NationalChartsProps) => {
  // We just remove the whole thing if any data is empty
  if (
    !data.whitehouse ||
    !data.senate ||
    !data.house ||
    data.whitehouse.length === 0 ||
    data.senate.length === 0 ||
    data.house.length === 0
  ) {
    return null;
  }

  return (
    <section className="flex flex-row flex-wrap justify-center gap-4 text-sm uppercase tracking-wider">
      <figure>
        <Chart data={data.whitehouse} />

        <figcaption className="mt-2">White House</figcaption>
      </figure>
      <figure>
        <Chart data={data.senate} />

        <figcaption className="mt-2">U.S. Senate</figcaption>
      </figure>
      <figure>
        <Chart data={data.house} />

        <figcaption className="mt-2">U.S. House</figcaption>
      </figure>
    </section>
  );
};

export { NationalCharts, Chart };
